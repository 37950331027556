:root{
  --blue:rgb(50,118,183);
  --darkgrey: #657786;
  --lightgrey: #AAB8C2;
  --extralightgrey: #E1E8ED;
  --extramaxlightgrey: #F5F8FA;
  --inputBG: #f0f0f0;
}
html,input,button{
  font-family: Questrial;
}
body{
  background-size: cover;
  background-image: url("./assets/bg.jpg");
  min-height:100vh;
  margin:0;
  scrollbar-width: none;
  overflow-x:hidden;
  font-size: 16px;
  background-color: var(--extramaxlightgrey);
}
body::-webkit-scrollbar { display:none;}

input, button, div{
  border-radius: 0px;
}
input:focus, button:focus {
  outline:none;
}
.react-toggle{
  flex-grow:0 !important;
  width:auto !important;
}
input{
  border:none;
  font-size: 1rem;
}
button{
  cursor: pointer;
}
.__react_component_tooltip{
  width:auto;
  flex-grow:0;
  font-size: 1.2rem !important;
}

.Modal {
  position: absolute;
  width:80vw;
  height:90vh;
  display:flex;
  background-color: white;
  box-sizing: content-box;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding:20px;
  outline: none !important;
  overflow-y: scroll;
  display: block;
}
@media (max-width: 600px) {
  .Modal{
    justify-content:unset;
  }
}


.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(1,1,1,0.3);
  display:flex;
  justify-content: center;
  align-items: center;
}

th,td{
  padding:10px !important;
  border-radius:0;
}
#live-chat-widget{
  display:unset;
  width:auto;
}

.pac-container{
  flex-direction: column;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}